.nav {
  position: absolute;
  left: 50%;
  bottom: 72px;
  transform: translate(-50%);

  display: flex;
  align-items: center;

  .navItem {
    display: block;
    padding: 16px;
    border: 1px solid #5d676b;
    border-radius: 50%;
    box-shadow: 0px 0px 8px 8px #5d676b0a;
    margin-right: 16px;
    cursor: pointer;
    font-size: 0;
    -webkit-user-select: none;
    user-select: none;

    img {
      width: 32px;
      height: 32px;
    }

    &:hover,
    &.navItemActive {
      border-color: #0081d4;
      box-shadow: 0px 0px 8px 8px #0081d440;
    }

    &:hover {
      // transform: translateY(-8px);
      transition: 0.2s;
    }

    object {
      pointer-events: none;
    }
  }

  .navLineItem {
    display: block;
    position: relative;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    &::before {
      display: block;
      content: "";
      width: 100%;
      height: 0;
      position: absolute;
      top: calc(50% - 1px);
      border-bottom: 2px solid black;
      opacity: 0;
    }
  }

  @media (max-width: 768px) {
    .navItem {
      display: none;
    }

    .navLineItem {
      display: none;
    }
  }

  @media (min-width: 768px) {
    .navItem {
      padding: 8px;
      margin-right: 8px;
      img {
        width: 16px;
        height: 16px;
      }
    }

    .navLineItem {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  @media (min-width: 992px) {
    .navItem {
      padding: 12px;
      margin-right: 12px;
      img {
        width: 24px;
        height: 24px;
      }
    }

    .navLineItem {
      width: 36px;
      height: 36px;
      margin-right: 12px;
    }
  }
  @media (min-width: 1200px) {
    .navItem {
      padding: 16px;
      margin-right: 16px;

      img {
        width: 32px;
        height: 32px;
      }
    }

    .navLineItem {
      width: 48px;
      height: 48px;
      margin-right: 16px;
    }
  }
}

.appearLeft {
  display: block;
  animation: fade-right 0.2s ease-out 0s 1 reverse;
}

.appearRight {
  display: block;
  animation: fade-left 0.2s ease-out 0s 1 reverse;
}

.fadeRight {
  display: block;
  animation: fade-right 0.2s ease-out 0s 1 normal;
}

.fadeLeft {
  display: block;
  animation: fade-left 0.2s ease-out 0s 1 normal;
}

.appearBottom {
  display: block;
  animation: fade-top 0.2s ease-out 0s 1 reverse;
}

.fadeTop {
  display: block;
  animation: fade-top 0.2s ease-out 0s 1 normal;
}

@keyframes fade-right {
  0% {
    opacity: 1;
    transform: translateX(-50%);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateX(32px);
  }
}

@keyframes fade-left {
  0% {
    opacity: 1;
    transform: translateX(-50%);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateX(-32px);
  }
}

@keyframes fade-top {
  0% {
    opacity: 1;
    transform: translateX(-50%);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(-32px);
  }
}
