
.sea {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 128px;
  width: 100vw;
  pointer-events: none;
}

.sea-wave {
  position: absolute;
  left: 0;
  top: 0;
  width: 5000px;
  height: 100%;
  background-image: url(../image/bg-wave.svg);
  background-repeat: repeat no-repeat;
  opacity: .2;
  transform-origin: center bottom;
}

.sea-wave.sea-wave-dark {
  animation: move-wave-dark 5s linear 0s infinite normal;
}

.sea-wave.sea-wave-light {
  animation: move-wave-light 5s linear 0s infinite normal;
}

@keyframes move-wave-dark {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1)
  }
  50% {
    transform: translateX(-675px) translateZ(0) scaleY(0.55)
  }
  100% {
    transform: translateX(-1350px) translateZ(0) scaleY(1)
  }
}

@keyframes move-wave-light {
  0% {
    transform: translateX(-180px) translateZ(0) scaleY(1)
  }
  50% {
    transform: translateX(-855px) translateZ(0) scaleY(0.8)
  }
  100% {
    transform: translateX(-1530px) translateZ(0) scaleY(1)
  }
}

.kraken {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;
}

.tentacle {
  position: absolute;
  width: calc(50% - 200px);
  height: 100%;
  background: url(../image/bg-tentacle.svg);
  background-size: cover;
  background-position: 0 -100px;
  background-repeat: no-repeat;
  opacity: .5;
}

.tentacle-left {
  left: 0;
  top: -50px;
  animation: rotate-tentacle-left 5s linear 0s infinite alternate;
}

.tentacle-right {
  right: 0;
  top: -50px;
  transform-origin: top right;
  animation: rotate-tentacle-right 5s linear 0s infinite alternate;
}

@keyframes rotate-tentacle-right {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(1deg);
  }
}

@keyframes rotate-tentacle-left {
  0% {
    transform: rotate(0deg) scaleX(-1);
  }
  50% {
    transform: rotate(1deg) scaleX(-1);
  }
  100% {
    transform: rotate(-1deg) scaleX(-1);
  }
}

@media (max-width: 768px) {
  .sea {
    display: none;
  }

  .kraken {
    display: none;
  }
}
